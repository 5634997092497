<template>
  <loading
    v-model:active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
  <div class="menu-container">
    <h4>Vendor / Menu List</h4>
    <p>In this section, enter your menu or list of products</p>
    <span>Create your menu list or products</span>

    <!-- table -->
    <div class="content-container">
      <table class="table-responsive" style="width: 100%">
        <tr>
          <th id="header">Menu List</th>
          <th id="header">Food Name</th>
          <th id="header">Description</th>
          <th id="header">Price #</th>
          <th id="header">% off (#)</th>
          <th id="header">Image</th>
          <div id="header"></div>
        </tr>
        <tr class="items-label">
          <td>
            <div class="f-details">
              <select class="form-select form-select-lg" aria-label="form-select-lg example" v-model="value.vendor_list_id">
                <option v-for="(vendor, index) in userVendors" :key="index" :value="vendor.id" selected="selected">
                  {{ vendor.category?.name }}
                </option>
              </select>
              <span id="validation" v-if="v$.vendor_list_id.$error">
                {{ v$.vendor_list_id.$errors[0].$message }}
              </span>
            </div>
          </td>
          <td>
            <div>
              <div class="food-name">
                <input v-model="value.name" type="text" />
              </div>
              <span id="validation" v-if="v$.name.$error">
                {{ v$.name.$errors[0].$message }}
              </span>
            </div>
          </td>
          <td>
            <div class="description">
              <input v-model="value.description" type="text" />
            </div>
            <span id="validation" v-if="v$.description.$error">
              {{ v$.description.$errors[0].$message }}
            </span>
          </td>
          <td>
            <div class="price">
              <input v-model="value.price" type="text" />
            </div>
            <span id="validation" v-if="v$.price.$error">
              {{ v$.price.$errors[0].$message }}
            </span>
          </td>
          <td>
            <div class="percentage-off">
              <input v-model="value.discount" type="text" />
            </div>
          </td>
          <td class="upload-img">
            <div class="food-image">
              <label for="file-input">
                <img :src="upload" alt="" />
              </label>
              <input id="file-input" @change="foodImage" type="file" />
            </div>
          </td>
          <td><div class="text-center"><Button id="submit" class="btn-sm" @click="SUBMIT" btnText="ADD" /></div></td>
        </tr>
      </table>
      <!-- <div class="action-btn">
        <Button id="add-more" btnText="Add More" />
      </div> -->
    </div>
    <div class="items-container">
    <table class="mt-5 table">
      <thead>
        <tr>
          <td scope="col"></td>
          <!--td scope="col">Food Menu</td-->
          <td scope="col">Food Name</td>
          <td scope="col">Description</td>
          <td scope="col">Price</td>
          <td scope="col">Discount</td>
          <td scope="col"></td>
        </tr>
      </thead>
      <tbody>
        <tr class="items-label" v-for="(m, index) in menus" :key="index">
          <td style="width: 8px;">
            <input type="checkbox" class="form-check-input"/>
          </td>
          <!--td>
            <div class="f-details d-flex">
              <img :src="food" alt="" />
              <div class="food_type">
                <div class="food-name"></div>
              </div>
            </div>
          </td-->
          <td>
            <div>
              <div class="food-name">{{ m.name }}</div>
            </div>
          </td>
          <td>
            <div class="food-name">{{ m.description }}</div>
          </td>
          <td>
            <div class="food-name">&#8358;{{ m.price }}</div>
          </td>
          <td>
            <div class="food-name">{{ m.discount }}%</div>
          </td>
          <td>
            <div class="row">
              <div class="col-4"><button class="btn btn-primary btn-sm text-nowrap" type="button"><i class="fa fa-edit"></i> Edit</button></div>
              <div class="col-4"><button class="btn btn-danger btn-sm text-nowrap" type="btn"><i class="fa fa-trash"></i> Delete</button></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    
  </div>
    <div class="action-btns">
      <div><Button id="previous" btnText="PREVIOUS" /></div>
      <div><Button id="submit" @click="SUBMIT" btnText="SUBMIT" /></div>
    </div>
  </div>
</template>

<script>
import Button from "../../../../components/ReusableUI/SmalllButton.vue";
import { reactive, computed } from "vue";
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import Loading from "vue-loading-overlay";
import Swal from "sweetalert2";

export default {
  setup() {
    // validation;
    const rules = computed(() => {
      return {
        vendor_list_id: { required },
        name: { required },
        description: { required },
        price: { required },
        // image: { required },
      };
    });

    const value = reactive({
      vendor_list_id: "",
      name: "",
      description: "",
      price: "",
      discount: "",
      image: null,
    });

    const v$ = useVuelidate(rules, value);
    return { value, v$ };
  },

  validations() {
    return {
      vendor_list_id: { required },
      name: { required },
      description: { required },
      price: { required },
    };
  },
  data() {
    return {
      upload: require("../../../../assets/upload_image.png"),
      isLoading: false,
      fullPage: true
    };
  },

  components: {
    Button,
    Loading,
  },

  methods: {
    ...mapActions({
      createMenu: "vendor/createMenu",
      showMenus: "vendor/getMenus"
    }),

    ...mapGetters({
      vendors: "vendor/availableUserVendors",
      vendor_info: "vendor/vendor_info"
    }),

    // method to get image value
    foodImage(event) {
      this.image = event.target.files[0];
    },

    SUBMIT(event) {
      event.preventDefault();
      this.v$.$validate();
      if (this.v$.$error) {
        return;
      } else {
        this.isLoading = true;
        const { vendor_list_id, name, description, price, discount } = this.value;
        var formData = new FormData();
        formData.append("vendor_list_id", vendor_list_id);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("price", price);
        formData.append("discount", discount);
        formData.append("image", this.image);
        this.createMenu(formData).then((data) => {
          if (data == undefined) {
            this.isLoading = false;
          }
          
          this.$store.dispatch('general/availableMenu')
          
          }).catch((err) => {
            this.isLoading = false
            Swal.fire("Server Error", err.response.data.message, "error");
          });
        this.value = "";
      }
    },
  },

  computed: {
    ...mapGetters({
      menus: "general/getMenus"
    }),
    
    
    userVendors() {
      return this.vendors();
    },
  },

  mounted() {
    console.log(this.vendor_info)
  }
};
</script>

<style scoped>
th {
  font-weight: 550;
  color: rgba(133, 77, 39, 1);
}

.food-name input {
  width: 110px;
  border: none;
  outline: none;
}

#validation {
  color: red;
  font-size: 10px;
}

.action-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20%;
}

.description {
  width: 40%;
}

.price input {
  outline: none;
  border: none;
  width: 60px;
}

.percentage-off input {
  outline: none;
  border: none;
  width: 60px;
}

.description input {
  width: 150px;
  border: none;
  outline: none;
}

.food-image input {
  display: none;
  cursor: pointer;
}

.food-image img {
  cursor: pointer;
}

.action-btn {
  text-align: right;
  margin-top: 2%;
}

.content-container {
  margin-top: 4%;
}

.upload-img {
  text-align: center;
  background: #f9f1f1;
  border: 2px dashed #7a7a7a;
  box-sizing: border-box;
  border-radius: 0px 5px 0px 0px;
  justify-content: center;
}

td {
  background: #ffffff;
  border: 0.5px solid #7a7a7a;
  box-sizing: border-box;
  /* border-radius: 15px; */
}

#add-more {
  background-color: rgba(67, 66, 76, 1);
}

#previous {
  background-color: #000;
  border: 1px solid gray;
  color: #000;
}

#submit {
  background-color: rgba(133, 77, 39, 1);
  /* color:#fff */
}

th {
  border: 0px solid #7a7a7a;
}

.menu-container {
  padding: 0% 3%;
}
.menu-container h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.menu-container span {
  color: rgba(4, 3, 15, 1);
  padding-top: 8%;
  font-weight: 600;
}

.category-container {
  padding: 0% 5%;
}

.form-select {
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 15px;
  color: black;
  padding: 0px 30px;
  padding-top: 10px;
  margin-bottom: 5px;
}
</style>
